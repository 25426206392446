import { NgModule } from '@angular/core';

import { geocemslabIfOnDomDirective } from '@geocemslab/directives/geocemslab-if-on-dom/geocemslab-if-on-dom.directive';
import { geocemslabInnerScrollDirective } from '@geocemslab/directives/geocemslab-inner-scroll/geocemslab-inner-scroll.directive';
import { geocemslabPerfectScrollbarDirective } from '@geocemslab/directives/geocemslab-perfect-scrollbar/geocemslab-perfect-scrollbar.directive';
import { geocemslabMatSidenavHelperDirective, geocemslabMatSidenavTogglerDirective } from '@geocemslab/directives/geocemslab-mat-sidenav/geocemslab-mat-sidenav.directive';

@NgModule({
    declarations: [
        geocemslabIfOnDomDirective,
        geocemslabInnerScrollDirective,
        geocemslabMatSidenavHelperDirective,
        geocemslabMatSidenavTogglerDirective,
        geocemslabPerfectScrollbarDirective
    ],
    imports     : [],
    exports     : [
        geocemslabIfOnDomDirective,
        geocemslabInnerScrollDirective,
        geocemslabMatSidenavHelperDirective,
        geocemslabMatSidenavTogglerDirective,
        geocemslabPerfectScrollbarDirective
    ]
})
export class geocemslabDirectivesModule
{
}
