import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { geocemslabDirectivesModule } from '@geocemslab/directives/directives';
import { geocemslabPipesModule } from '@geocemslab/pipes/pipes.module';
import { geocemsMaterialSharedModule } from './material.module';

@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        geocemslabDirectivesModule,
        geocemslabPipesModule,
        geocemsMaterialSharedModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        geocemslabDirectivesModule,
        geocemslabPipesModule,
        geocemsMaterialSharedModule
    ]
})
export class geocemslabSharedModule
{
}
