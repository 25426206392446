import { NgModule } from '@angular/core';

import { geocemslabWidgetComponent } from './widget.component';
import { geocemslabWidgetToggleDirective } from './widget-toggle.directive';

@NgModule({
    declarations: [
        geocemslabWidgetComponent,
        geocemslabWidgetToggleDirective
    ],
    exports     : [
        geocemslabWidgetComponent,
        geocemslabWidgetToggleDirective
    ],
})
export class geocemslabWidgetModule
{
}
