import { geocemslabNavigation } from '@geocemslab/types';

export const navigation: geocemslabNavigation[] = [
    {
        id       : 'dashboard',
        title    : 'Dashboard',
        type     : 'group',
        icon     : 'dashboard',
        children : [
            {
                id       : 'home',
                title    : 'Home',
                type     : 'item',
                icon     : 'home',
                url      : '/admin/apps/home',
            },
            {
                id       : 'message',
                title    : 'Message',
                type     : 'item',
                icon     : 'chat',
                url      : '/admin/apps/chat',
                badge   : {
                    title: '10',
                    bg   : '#525e8a',
                    fg   : '#FFFFFF'
                },
            }
        ]
    },
    {
        id      : 'manage',
        title   : 'Manage',
        type    : 'group',
        icon    : 'settings',
        children: [
            {
                id      : 'schedules',
                title   : 'Schedules',
                type    : 'item',
                icon    : 'schedule',
                url     : '/admin/apps/schedule'
            },
            {
                id   : 'staff',
                title: 'Staff',
                type : 'item',
                icon : 'account_circle',
                url  : '/admin/apps/staff'
            },
            {
                id   : 'department',
                title: 'Department',
                type : 'item',
                icon : 'meeting_room',
                url  : '/admin/apps/department'
            },
            {
                id   : 'profile',
                title: 'Profile',
                type : 'item',
                icon : 'supervisor_account',
                url  : '/admin/apps/profile'
            },
            {
                id   : 'reports',
                title: 'Reports',
                type : 'item',
                icon : 'table_chart',
                url  : '/admin/apps/reports'
            },
            // {
            //     id   : 'modular',
            //     title: 'Modules',
            //     type : 'item',
            //     icon : 'apps',
            //     url  : '/admin/apps/modular'
            // }

        ]
    },
    {
        id      : 'support',
        title   : 'Support',
        type    : 'group',
        icon    : 'web',
        children: [
            {
                id   : 'help',
                title: 'Help',
                type : 'item',
                icon : 'live_help',
                url  : '/admin/apps/help'
            },
            {
                id   : 'faqs',
                title: 'Faqs',
                type : 'item',
                icon : 'question_answer',
                url  : '/admin/apps/faqs'
            }
        ]
    }
];
