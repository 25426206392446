import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService } from '@geocemslab/generic.service';
import { Login, Counts } from '@geocemslab/all.models';
import { JsonConvert } from 'json2typescript';
import { delay } from 'q';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {


    // onErrorCredChanged: BehaviorSubject<any>;
    isSuccessful = false;
    

    constructor(
        private route: Router,
        private netService: NetworkService
    ) {
        // this.onErrorCredChanged = new BehaviorSubject({});
  
    }

    isLoggedIn(): boolean {
        // TODO Validate token from server before returning
        return !!localStorage.getItem('token');
    }

    getTokenOrId(type): string {
        // TODO Validate token from server before returning
        switch (type){
            case 'token':
            return localStorage.getItem('token');
            case 'id':
            return localStorage.getItem('id');
        }
    }

    logout(): boolean {
        localStorage.clear();
        this.route.navigate(['/']);
        return true;
    }


     
   

}
