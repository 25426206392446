export class FaqFakeDb
{
    public static data = [
        {
            'question': 'Can I set Alarm for my duty?',
            'answer': 'Yes, You can set an Alarm for a duty, However duty alarms can be set for your own duty. Not other peoples duty'
        },
        {
            'question': 'Can I swap a duty with a colleague doctor?',
            'answer': 'Swapping duty with a doctor can be done but it will be done at your own risk.'
        },
        {
            'question': 'How do I change my password?',
            'answer': 'You will be allowed to change your password after confirming to the email to change your password, through the mobile app'
        },
        {
            'question': 'How do I report mistake in my duty?',
            'answer': 'We are please to inform you that we have a chat system in the mobile app to directly communicate with your admin on issues like that or send a help report'
        },
        {
            'question': 'Do I need credit all the time to see my duties.?',
            'answer': 'We will recommend you do have internet at certain times but not all the time, since your app needs to fetch duty for the month once.'
        },
        {
            'question': 'Can I log in to the app with different phone?',
            'answer': 'Yes, you will be able to use a different phone but your concern will be seeked through the confirmation of an email that will be sent to you.'
        },
        {
            'question': 'Forgotten password?',
            'answer': 'You can reset your password by providing to us your username or email, and the required activities will be sent to your email'
        },
        // {
        //     'question': 'Non cupidatat enim quis aliquip minim laborum?',
        //     'answer': 'Qui cillum eiusmod nostrud sunt dolore velit nostrud labore voluptate ad dolore. Eu Lorem anim pariatur aliqua. Ullamco ut dolor velit esse occaecat dolore eu cillum commodo qui. Nulla dolor consequat voluptate magna ut commodo magna consectetur non aute proident.'
        // },
        // {
        //     'question': 'Dolor ex occaecat magna labore laboris qui?',
        //     'answer': 'Incididunt qui excepteur eiusmod elit cillum occaecat voluptate cillum nostrud. Dolor ullamco ullamco eiusmod do sunt adipisicing pariatur. In esse esse labore id reprehenderit sint do. Pariatur culpa dolor tempor qui excepteur duis do anim minim ipsum.'
        // },
        // {
        //     'question': 'Nisi et ullamco minim ea proident tempor?',
        //     'answer': 'Dolor veniam dolor cillum Lorem magna nisi in occaecat nulla dolor ea eiusmod.'
        // },
        // {
        //     'question': 'Amet sunt et quis amet commodo quis?',
        //     'answer': 'Nulla dolore consequat aliqua sint consequat elit qui occaecat et.'
        // },
        // {
        //     'question': 'Ut eiusmod ex ea eiusmod culpa incididunt?',
        //     'answer': 'Fugiat non incididunt officia ex incididunt occaecat. Voluptate nostrud culpa aliquip mollit incididunt non dolore.'
        // },
        // {
        //     'question': 'Proident reprehenderit laboris pariatur ut et nisi?',
        //     'answer': 'Reprehenderit proident ut ad cillum quis velit quis aliqua ut aliquip tempor ullamco.'
        // },
        // {
        //     'question': 'Aliqua aliquip aliquip aliquip et exercitation aute?',
        //     'answer': 'Adipisicing Lorem tempor ex anim. Labore tempor laboris nostrud dolore voluptate ullamco. Fugiat ex deserunt anim minim esse velit laboris aute ea duis incididunt. Elit irure id Lorem incididunt laborum aliquip consectetur est irure sunt. Ut labore anim nisi aliqua tempor laborum nulla cillum. Duis irure consequat cillum magna cillum eiusmod ut. Et exercitation voluptate quis deserunt elit quis dolor deserunt ex ex esse ex.'
        // }
    ];
}
