import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { geocemslabAnimations } from '@geocemslab/animations';
import { AuthService } from './auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NetworkService } from '@geocemslab/generic.service';
import { Gateway } from '@geocemslab/all.endpoints';


@Component({
    selector: 'auth-component',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
    animations: geocemslabAnimations

})
export class AuthComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loginDetails: any[];
    isLoading: any;
    isValid = false;
    oldpassword;
    hideButton = false;
    // private _unsubscribeAll: Subject<any>;




    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private _authService: AuthService,
        private _netService: NetworkService,
        private router: Router,

    ) {
        // this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isLoggedInAlready();
        this.loginForm = this._formBuilder.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            grant_type: ['password']
        });
        // this.loginForm.valueChanges.subscribe(
        //     data => {
        //         if(this.onChangePassword(data.length,this.oldpassword)){
        //             this.isValid = true
        //         }
        //     }
        // );
    }



    isLoggedInAlready() {
        if (this._authService.isLoggedIn()) {
            this.router.navigate(['admin']);
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions

    }

    async login() {
        this.isLoading = true;
        this.isValid = false;
        this.hideButton = true;
        this.loginDetails = this.loginForm.value;
        console.log(this.loginDetails);
        this._netService.asyncPost<any>(Gateway.USER_LOGIN, this.loginDetails).subscribe(
            (response) => {
                if (!!response) {
                    console.log(response.status);
                    console.log(response);
                    this.isLoading = false;
                    this.setResponseToken(response);
                    this.afterSignIn(response);
                }
                else {
                    console.log('Status was okay but shit happened');
                    this.hideButton = false;
                }
            },
            (error) => {
                this.isLoading = false;
                this.isValid = true;
                this.hideButton = false;
                this._netService.handleError(error);
                console.log(error);
            }
        );
    }

    public afterSignIn (response): void {
        window.location.href = 'admin';
    }

    public setResponseToken (response): void {
        if (response != null && response[0].token !== undefined) {
            localStorage.setItem('token', response[0].token);
            localStorage.setItem('id', response[0].user_id);
            localStorage.setItem('facility', response[0].facility);
        }
    }



}
