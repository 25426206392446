import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { geocemslabModule } from '@geocemslab/geocemslab.module';
import { geocemslabSharedModule } from '@geocemslab/shared.module';
import { geocemslabProgressBarModule, geocemslabSidebarModule, geocemslabThemeOptionsModule } from '@geocemslab/components';


import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { geocemslab_Config } from './geocemslab-config';
import { AuthComponent } from './auth/auth.component';
import { AdminComponent } from './admin/admin.component';
import { ForgottenPasswordComponent } from './auth/fogottenpassword/forgotten.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthServiceModule } from './auth/auth.servicemodule';
import { LoginGuard } from './auth/login.guard';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full'
    },
    {
        path: 'auth/login',
        component: AuthComponent,
    },
    {
        path: 'auth/forgottenpassword',
        component: ForgottenPasswordComponent
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate:[AuthGuard],
        children: [
            {
                path: '',
                loadChildren: './admin/admin.module#AdminModule'
            }
        ]
    },
    {
        path: '**',
        component: ForgottenPasswordComponent
    },
];

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        AdminComponent,
        ForgottenPasswordComponent
    ],
    imports: [
        // Imported the Broswer module and other modules as you can see,
        // It is actually like making this module aware of the other modules
        // By importing then in the ngModule though you have imported them above
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        geocemslabProgressBarModule,
        geocemslabSharedModule,
        geocemslabSidebarModule,
        geocemslabThemeOptionsModule,
        geocemslabModule.forRoot(geocemslab_Config),
        // App modules
        LayoutModule,
        AppStoreModule,
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        
        AuthServiceModule
    ],
    providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
      ],
    bootstrap: [
        AppComponent,
    ],

})
export class AppModule {
}

