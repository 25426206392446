import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { geocemslab_CONFIG } from '@geocemslab/services/config.service';
import { NetworkService } from './generic.service';

@NgModule()
export class geocemslabModule
{
    constructor(@Optional() @SkipSelf() parentModule: geocemslabModule)
    {
        if ( parentModule )
        {
            throw new Error('geocemslabModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config): ModuleWithProviders
    {
        return {
            ngModule : geocemslabModule,
            providers: [
                {
                    provide : geocemslab_CONFIG,
                    useValue: config
                }
           ]
        };
    }
}
