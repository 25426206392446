import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { geocemslabConfigService } from '@geocemslab/services/config.service';
import { geocemslabNavigationService } from '@geocemslab/components/navigation/navigation.service';
import { geocemslabSidebarService } from '@geocemslab/components/sidebar/sidebar.service';
import { geocemslabSplashScreenService } from '@geocemslab/services/splash-screen.service';
import { geocemslabTranslationLoaderService } from '@geocemslab/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';

@Component({
    selector   : 'admin-component',
    templateUrl: './admin.component.html',
    styleUrls  : ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy
{
    geocemslabConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {geocemslabConfigService} _geocemslabConfigService
     * @param {geocemslabNavigationService} _geocemslabNavigationService
     * @param {geocemslabSidebarService} _geocemslabSidebarService
     * @param {geocemslabSplashScreenService} _geocemslabSplashScreenService
     * @param {geocemslabTranslationLoaderService} _geocemslabTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _geocemslabConfigService: geocemslabConfigService,
        private _geocemslabNavigationService: geocemslabNavigationService,
        private _geocemslabSidebarService: geocemslabSidebarService,
        private _geocemslabSplashScreenService: geocemslabSplashScreenService,
        private _geocemslabTranslationLoaderService: geocemslabTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform
    )
    {
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._geocemslabNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._geocemslabNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._geocemslabTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._geocemslabConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.geocemslabConfig = config;

                if ( this.geocemslabConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._geocemslabSidebarService.getSidebar(key).toggleOpen();
    }
}
