import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { geocemslabAnimations } from '@geocemslab/animations';



@Component({
    selector   : 'forgotten-component',
    templateUrl: './forgotten.component.html',
    styleUrls  : ['./forgotten.component.scss'],
    animations : geocemslabAnimations

})
export class ForgottenPasswordComponent implements OnInit
{
    ngOnInit(): void {
    }

}
