import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  
  constructor(private appServ: AuthService,
            private route: Router){

  }

  canActivate(): boolean {
   return this.isValidUser();
  }

  canActivateChild(): boolean{
    return this.isValidUser();
  }

  isValidUser(): boolean{
    if (this.appServ.isLoggedIn()){
      return true;
    } else{
        this.route.navigate(['/auth/login']);
        return false;
    }
  }

  
}
