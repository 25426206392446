import { NgModule } from '@angular/core';

import { geocemslabHighlightComponent } from '@geocemslab/components/highlight/highlight.component';

@NgModule({
    declarations: [
        geocemslabHighlightComponent
    ],
    exports: [
        geocemslabHighlightComponent
    ],
})
export class geocemslabHighlightModule
{
}
