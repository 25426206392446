import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatRadioModule, MatSelectModule, MatSlideToggleModule } from '@angular/material';

import { geocemslabDirectivesModule } from '@geocemslab/directives/directives';
import { geocemslabSidebarModule } from '@geocemslab/components/sidebar/sidebar.module';
import { geocemslabMaterialColorPickerModule } from '@geocemslab/components/material-color-picker/material-color-picker.module';

import { geocemslabThemeOptionsComponent } from '@geocemslab/components/theme-options/theme-options.component';

@NgModule({
    declarations: [
        geocemslabThemeOptionsComponent
    ],
    imports     : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,

        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatOptionModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,

        geocemslabDirectivesModule,
        geocemslabMaterialColorPickerModule,
        geocemslabSidebarModule
    ],
    exports     : [
        geocemslabThemeOptionsComponent
    ]
})
export class geocemslabThemeOptionsModule
{
}
