import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Login, User } from './all.models'
import { ResponseType, RequestOptions, ResponseContentType } from '@angular/http';
import { Gateway } from './all.endpoints';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {


  constructor(private httpClient: HttpClient) {
  }

  login(credentials: any[]): Observable<Login> {
    return this.httpClient.post<any>(Gateway.USER_LOGIN, credentials).pipe(
      catchError(this.handleError)
    );
  }

  getUser(userId: number): Observable<User> {
    return this.httpClient.get<any>(Gateway.GET_USER + userId).pipe(
      catchError(this.handleError)
    );
  }

  sendFile(url: string, file: File): Observable<any> {
    return this.httpClient.post(Gateway.host + url, file);
  }

  downloadSchedule(): void {
      const uid = {'uid': localStorage.getItem('id')};
      this.asyncFileDownload<Blob>(Gateway.TEMPLATE_DOWNLOAD, uid).subscribe(
          (response) => {
              if (!!response) {
                  saveAs(response, this.getMonthForScheduleTemplate() + '_Schedule.xlsx');
              }
          }, (error) => {
              console.log(error);
          }
      );

  }



     /**
     * Get month for schedule
     *
     * @returns {string}
     */
    getMonthForScheduleTemplate(): string {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
      ];

      const d = new Date();
      return monthNames[d.getMonth()];
  }

  //   TODO this handle error will send every event of error to mail
  //   Together with the IP or some stuff of the user to help trace the bug
  //   TAKE NOTE

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    }
    else if (error.status != null) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // Handle Http Error (error.status === 403, 404...)
      switch (error.status) {
        case 401: return throwError('Please tell user unauthorize access');
        case 500: return throwError('Server down');
        case 404: return throwError('Resource not available')
      }
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.message}`);
    }
    // return an observable with a human_interface error message
    else {
      return throwError(
        'Something bad happened; please try again later.');
    }

  };


  // GENERICS

  public asyncFileDownload<T>(url: string, userInfo: any): Observable<Blob> {
    return this.httpClient.post(url, userInfo, {responseType: 'blob'});
  }

  public asyncGetFile<T>(url): Observable<Blob>{
    return this.httpClient.get(url, {responseType: 'blob'});
  }

  public asyncGetAll<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url).pipe(
      catchError(this.handleError)
    );
  }

  public asyncGetSingle<T>(url: string, id: number): Observable<T> {
    return this.httpClient.get<T>(`${url}/${id}`);
  }

  public asyncPut<T>(url: string, id: number, itemName: any): Observable<T>{
    return this.httpClient.put<T>(`${url}/${id}`, itemName);
  }

  public asyncPost<T>(url: string, itemName: any[]): Observable<T> {
    // const toAdd = JSON.stringify({ ItemName: itemName });
    return this.httpClient.post<T>(url, itemName);
  }
  public asyncPostSingle<T>(url: string, itemName: any): Observable<T> {
    // const toAdd = JSON.stringify({ ItemName: itemName });
    return this.httpClient.post<T>(url, itemName);
  }
  public asyncUpdate<T>(url: string, id: number, itemToUpdate: any[]): Observable<T> {
    return this.httpClient
      .put<T>(Gateway.host + id.toString(), JSON.stringify(itemToUpdate)).pipe(
        catchError(this.handleError)
      );
  }

  public asyncDelete<T>(url: string, id: number): Observable<T> {
    return this.httpClient.delete<T>(`${url}/${id}`);
  }

}
