import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatIconModule, MatMenuModule, MatRippleModule } from '@angular/material';

import { geocemslabPipesModule } from '@geocemslab/pipes/pipes.module';

import { geocemslabMaterialColorPickerComponent } from '@geocemslab/components/material-color-picker/material-color-picker.component';

@NgModule({
    declarations: [
        geocemslabMaterialColorPickerComponent
    ],
    imports: [
        CommonModule,

        FlexLayoutModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatRippleModule,

        geocemslabPipesModule
    ],
    exports: [
        geocemslabMaterialColorPickerComponent
    ],
})
export class geocemslabMaterialColorPickerModule
{
}
