import { NgModule } from '@angular/core';

import { geocemslabCountdownComponent } from '@geocemslab/components/countdown/countdown.component';

@NgModule({
    declarations: [
        geocemslabCountdownComponent
    ],
    exports: [
        geocemslabCountdownComponent
    ],
})
export class geocemslabCountdownModule
{
}
