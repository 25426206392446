import { Injectable, Injector, Type } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from './auth.service';

// This is the interceptor class that implements a method from the HTTPINterceptor from angular HTTP
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const auth  = this.injector.get(AuthService);
    const authToken = auth.getTokenOrId('token');
 
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    });
 
    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }

  //  intercept(req, next): any {
  //    const auth = this.injector.get(AuthService);
  //    const tokenObtained = auth.getTokenOrId('token');
  //   const tokenizedRequest = req.clone({
  //       setHeaders: {
  //         Authorization: `Bearer ${tokenObtained}`,
  //       }
  //   });
  //   return next.handle(tokenizedRequest);
  //  }
   
   


}
