import { NgModule } from '@angular/core';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatDividerModule, 
    MatIconModule, MatMenuModule, MatSelectModule, MatTableModule, MatTabsModule, 
    MatCardModule, MatListModule, MatProgressSpinnerModule, MatChipsModule, 
    MatAutocompleteModule, MatButtonToggleModule, MatDatepickerModule, MatToolbarModule, 
    MatProgressBarModule, MatAccordion, MatExpansionModule, MatPaginatorModule } from '@angular/material';

@NgModule({
    imports  : [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatCardModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule
    ],
    exports  : [
        
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTableModule,
        MatTabsModule,
        MatCardModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule

    ]
})
export class geocemsMaterialSharedModule
{
}
