import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { NetworkService } from '@geocemslab/generic.service';
import { LoginGuard } from './login.guard';


@NgModule({
  providers: [AuthService,AuthGuard,LoginGuard,NetworkService]
})
export class AuthServiceModule {}
