


import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class LoginGuard implements  CanActivateChild {
  
  constructor(private appServ: AuthService,
            private route: Router){

  }

//   canActivate(): boolean {
//    return this.isNotLogged();
//   }

  canActivateChild(): boolean{
    return this.isNotLogged();
  }

  isNotLogged(): boolean{
    if (this.appServ.isLoggedIn()){
      return false;
    } else{
        this.route.navigate(['/admin']);
        return true;
    }
  }

  
}
