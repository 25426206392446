export class Gateway {
    // public static host: String = 'http://127.0.0.1:8080/api/v0/';
    public static host: String = 'https://apomuden-py37.appspot.com/api/v0/';
    public static readonly GET_ALL_STAFFS = Gateway.host + 'adminStaff';
    public static readonly GET_ALL_DEPARTMENTS = Gateway.host + 'allDepartments';
    public static readonly GET_DUTIES = Gateway.host + 'adminDuty';
    public static readonly GET_USER = Gateway.host +  'adminUsers/';
    public static readonly USER_LOGIN = Gateway.host + 'adminlogin';
    public static readonly COUNT = Gateway.host + 'counts';
    public static readonly LOGS = Gateway.host + 'getLog/';
    public static readonly UPLOAD_SCHEDULE = Gateway.host + 'uploadSchedule';
    public static readonly TEMPLATE_DOWNLOAD = Gateway.host + 'fileDownload';
    public static readonly UP_DOWN_LOAD_USER_TEMP = Gateway.host + 'uploadUsers';
    public static readonly UPDATE_STAFF = Gateway.host + 'updateUser';
    public static readonly SAVE_STAFF = Gateway.host + 'saveUser';
    public static readonly DELETE_STAFF = Gateway.host + 'deleteUser';
    public static readonly GET_ALL_POSITIONS = Gateway.host + 'allPositions';

}


