import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Router, Event } from '@angular/router';


@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    loading: any;
    ngOnInit(): void {
    }   
    
    ngOnDestroy(): void {
    }

    constructor(private router: Router){
        router.events.subscribe((routerEvent: Event) => {
            this.checkRouterEvent(routerEvent);
        });
    }

    checkRouterEvent(routerEvent: Event): void{
        if (routerEvent instanceof NavigationStart){
            this.loading = true
        }
        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel||
            routerEvent instanceof NavigationError
            ){

                this.loading = false
            }

    }

   
}
