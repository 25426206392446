import { Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateFormat'
  })
  export class DateFormatPipe extends DatePipe implements PipeTransform {
    viewRequestTransform(value: any, args?: any): any {
       ///MMM/dd/yyyy 
       return super.transform(value, "yyyy-MM-dd");
    }

    //There will be other methods that indicates how other dates format will look like
  }